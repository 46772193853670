{
  "name": "365Portal",
  "version": "0.1.6",
  "engines": {
    "node": "20.11.1"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl",
    "build": "ng build",
    "watch": "ng serve --configuration development --ssl",
    "forsyning": "ng serve --configuration forsyning-dev --ssl",
    "serve-libra": "ng serve --configuration libra-dev --ssl",
    "build-libra-prod": "ng build --configuration libra-prod",
    "build-libra-dev": "ng build --configuration libra-dev",
    "build-forsyning-prod": "ng build --configuration forsyning-prod",
    "build-forsyning-dev": "ng build --configuration forsyning-dev",
    "!": "npx ng serve",
    "!b": "npx ng build",
    "!bp": "npx ng build --configuration production",
    "lint": "ng lint",
    "build-lib": "ng build portal-lib",
    "publish-lib": "npm publish ./dist/portal-lib/",
    "build-publish-lib": "npm run build-lib && npm run publish-lib",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@cloud-and-service/portal-lib": "^0.4.12",
    "@ngxs/storage-plugin": "^18.1.1",
    "@ngxs/store": "^18.1.5",
    "@swimlane/ngx-charts": "^21.0.0",
    "@zxing/browser": "^0.1.1",
    "@zxing/library": "^0.21.0",
    "@zxing/ngx-scanner": "^18.0.0",
    "angular-user-idle": "^4.0.0",
    "bootstrap": "^5.1.3",
    "clone-deep": "^4.0.1",
    "guid-typescript": "^1.0.9",
    "lodash": "^4.17.21",
    "moment": "^2.29.1",
    "ng-http-loader": "^16.0.0",
    "ngx-bootstrap": "^18.0.2",
    "ngx-toastr": "^19.0.0",
    "rxjs": "^7.5.1",
    "signature_pad": "^5.0.1",
    "tslib": "^2.7.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@ngxs/devtools-plugin": "^18.1.1",
    "@types/d3": "^7.1.0",
    "@types/jasmine": "^5.1.1",
    "@types/lodash": "^4.14.182",
    "@types/node": "^22.5.5",
    "@typescript-eslint/eslint-plugin": "^8.6.0",
    "@typescript-eslint/parser": "^8.6.0",
    "eslint": "^9.10.0",
    "eslint-plugin-unused-imports": "^4.1.4",
    "jasmine-core": "^5.0.1",
    "karma": "^6.4.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage": "^2.1.0",
    "karma-jasmine": "^5.0.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "ng-packagr": "^18.0.0",
    "typescript": "^5.4.5"
  }
}
