import { Component, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  EnvironmentService,
  LocaleService,
  MainState,
  ModalService,
  TranslationService as PortalTranslationService,
  Role,
  User,
  UtilityService
} from '@cloud-and-service/portal-lib';
import { Store } from '@ngxs/store';
import { RedirectType } from 'src/app/models/redirect-type';
import { CallBackService } from 'src/app/services/callback.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  username: string | null = null;
  password: string | null = null;

  loginPageSettings: any;

  logo: Signal<string | null>;
  title: Signal<string | null>;

  selectedLanguage: Signal<string | null>;
  cultures: string[];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private store: Store,
    private callBackService: CallBackService,
    private utilityService: UtilityService,
    private locationService: LocationService,
    public consumptionService: ConsumptionService,
    private modalService: ModalService,
    private translation: PortalTranslationService,
    private localeService: LocaleService,
    environmentService: EnvironmentService
  ) {
    var environment = environmentService.getEnvironment();
    this.loginPageSettings = environment.loginPageSettings;
    this.logo = this.store.selectSignal(MainState.logo);
    this.title = this.store.selectSignal(MainState.title);
    this.cultures = this.localeService.listLanguages();
    this.selectedLanguage = this.localeService.currentLanguageSignal;
  }

  ngOnInit(): void {
    this.modalService.closeAllModals();
  }

  async login() {
    if (!this.username || !this.password) {
      return;
    }

    await this.authService.login(this.username, this.password);

    const auth = this.store.selectSnapshot(MainState.auth);
    if (!auth) {
      this.modalService.error(this.translation.getTranslation('ERROR_LOGIN'));
      return;
    }

    const _user: User = await this.userService.getCurrentUser();
    if (!this.store.selectSnapshot(MainState.user)) {
      this.modalService.error(this.translation.getTranslation('ERROR_CURRENT_USER'));
      return;
    }

    if (!this.store.selectSnapshot(MainState.translations)?.length) {
      return;
    }

    if (_user.role == Role.user) {
      await this.locationService.presentLocations(true);

      if (!this.utilityService.isEmpty(auth.urlRoute)) {
        this.router.navigate([`${auth.urlRoute}/home`]);
      } else {
        this.router.navigate(['/']);
      }
    } else if (_user.role == Role.admin) {
      this.router.navigate(['admin']);
    }
  }

  async getIdpService(service: string) {
    const uri: any = await this.callBackService.getIdpBroker(
      this.store.selectSnapshot(MainState.routePrefix),
      service,
      RedirectType.login
    );
    window.open(uri.url, '_self');
  }

  changeLocale(event$: any) {
    this.localeService.updateLocale(event$.target.value);
  }
}
