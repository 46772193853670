import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-log-list',
  templateUrl: './admin-log-list.component.html',
  styleUrls: ['./admin-log-list.component.scss'],
  standalone: false,
})
export class AdminLogListComponent {

  constructor() { }

  throwError() {
    throw Error("The app component has thrown an error!");

  }
}
