import { Component, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Environment, EnvironmentService } from '@cloud-and-service/portal-lib';
import { ConnectionType } from '@cloud-and-service/portal-lib';
import { Tenant } from '@cloud-and-service/portal-lib';
import { TenantService } from 'src/app/services/tenant.service';
import { AdminTenantDetailComponent } from '../admin-tenant-detail/admin-tenant-detail.component';


@Component({
  selector: 'app-admin-tenant-list',
  templateUrl: './admin-tenant-list.component.html',
  styleUrls: ['./admin-tenant-list.component.scss'],
  standalone: false,
})
export class AdminTenantListComponent implements OnInit {

  tenants: Tenant[] = [];
  environment: Environment;

  constructor(
    private tenantService: TenantService,
    private modalService: BsModalService,
    private environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  async ngOnInit(): Promise<void> {
    this.getTenants();
    this.environment = this.environmentService.getEnvironment();
  }

  getTenants() {
    this.tenantService.getTenants()
      .then(
        (tenants: Tenant[]) => {
          this.tenants = tenants.filter(t => this.showTenantFilter(t, this.environment));
        }
      );
  }

  private showTenantFilter(tenant: Tenant, environment: Environment): boolean {
    if (tenant.connectionType === ConnectionType.File) {
      return environment.environment === 'local';
    }
    return true;
  }

  tenantDetail(tenant: Tenant = {} as Tenant): void {
    const initialState: ModalOptions = {
      initialState: {
        tenant,
      },
       class: 'modal-lg'
    };
    this.modalService.show(AdminTenantDetailComponent, initialState);
  }

}
