import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { AuthService, RelayService } from '@cloud-and-service/portal-lib';
import { CallBackService } from 'src/app/services/callback.service';
import { UserService } from 'src/app/services/user.service';

import { IdpAuth, Location, MainState, SetIdpAuth, UtilityService } from '@cloud-and-service/portal-lib';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  errormsg: string = '';
  showError: boolean = false;
  showAssignUser: boolean = false;
  showSelectContract: boolean = false;

  // New contract
  username: string = '';
  password: string = '';
  locations: Location[] = {} as Location[];

  resetTimer: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private callBackService: CallBackService,
    private userService: UserService,
    private authService: AuthService,
    private relayService: RelayService,
    private store: Store,
    private router: Router
  ) {}

  async ngOnInit() {
    const params = await firstValueFrom(this.activatedRoute.queryParams.pipe());

    if (this.utilityService.isEmpty(params) || this.utilityService.isEmpty(params['code'])) {
      this.router.navigate(['login']);
    } else {
      this.processIdpUser(params['code']);
    }
  }

  setResetTimer(seconds: number) {
    if (this.resetTimer !== null) {
      clearTimeout(this.resetTimer);
    }

    if (seconds > 0) {
      this.resetTimer = setTimeout(() => {
        this.authService.logout();
      }, seconds * 1000);
    }
  }

  async loginWithIdp(idpAuth: IdpAuth) {
    await this.callBackService
      .getIdpContracts(idpAuth)
      .then(async (result: Location[]) => {
        this.locations = result;
         if (this.utilityService.isEmpty(this.locations)) {
           this.router.navigate(['assignuser']);
        } else {
          if (this.locations.length > 1) {
            this.showSelectContract = true;
            this.setResetTimer(60);
          } else {
            await this.doLogin(this.locations[0].locationGuid);
          }
        }
      })
      .catch((error: any) => {
        this.displayError(error);
      });
  }

  async processIdpUser(code: string) {
    const idpAuth = await this.callBackService
      .getIdpUser(this.store.selectSnapshot(MainState.routePrefix), code)
      .catch((err) => {
        this.displayError(`Identificering af bruger fejlede. [${err}]`);
        return {} as IdpAuth;
      });

    if (this.utilityService.isEmpty(idpAuth)) {
      this.displayError('Manglede / tom identificeringsoplysninger.');
    } else {
      this.store.dispatch(SetIdpAuth);
      this.loginWithIdp(idpAuth);
    }
  }

  async doLogin(locationguid: string) {
    if (!this.utilityService.isEmpty(locationguid)) {
      const auth = await this.authService.loginWithIdpLocation(locationguid);

      if (!auth) {
        return;
      }

      if (!this.store.selectSnapshot(MainState.auth)) {
        return;
      }

      await this.userService.getCurrentUser();
      if (!this.store.selectSnapshot(MainState.user)) {
        return;
      }

      if (auth.location !== null && auth.relation !== null) {
        const locationRequest: Location = {} as Location;
        locationRequest.relationId = auth.relation;
        locationRequest.locationGuid = auth.location;
        await this.relayService.setLocation(locationRequest);
      }

      if (!this.store.selectSnapshot(MainState.translations)?.length) {
        return;
      }

      if (!this.utilityService.isEmpty(auth.urlRoute)) {
        this.router.navigate([`${auth.urlRoute}/home`]);
      } else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  async onUseContract(index: number) {
    clearTimeout(this.resetTimer);
    if (index != null) {
      await this.doLogin(this.locations[index].locationGuid);
    }
  }

  async toLogin() {
    clearTimeout(this.resetTimer);
    this.router.navigate(['login']);
  }

  displayError(error: any) {
    this.errormsg = error;

    if (!this.utilityService.isEmpty(error.error)) {
      this.errormsg = error.error;
    }

    if (!this.utilityService.isEmpty(error.status)) {
      this.errormsg += ` [${error.status}]`;
    }
    this.showError = true;
  }
}
