import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  Confirm,
  ConfirmOption,
  ElementService,
  EnvironmentService,
  HistoryService,
  MainState,
  ModalService,
  PortalComponent,
  TranslationService as PortalTranslationService,
  PortalUpdateEvent,
  RelayService,
  Settings,
  User
} from '@cloud-and-service/portal-lib';
import { Select, Store } from '@ngxs/store';
import { UserIdleService } from 'angular-user-idle';
import { debounce } from 'lodash';
import { Observable, takeUntil } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false,
})
export class HomeComponent extends PortalComponent implements OnInit {
  @ViewChild('root') override root: ElementRef | null = null;

  @Select(MainState.user) user$: Observable<User> | undefined;
  @Select(MainState.location) location$: Observable<Location> | undefined;
  @Select(MainState.logo) logo$: Observable<string> | undefined;
  @Select(MainState.settings) settings$: Observable<Settings> | undefined;

  hideLocationChanger: boolean;

  size: string = 'large';
  showingLogoutWarning = false;

  constructor(
    public locationService: LocationService,
    public authService: AuthService,
    private userIdleService: UserIdleService,
    private translation: PortalTranslationService,
    historyService: HistoryService,
    relayService: RelayService,
    elementService: ElementService,
    environmentService: EnvironmentService,
    store: Store,
    modalService: ModalService
  ) {
    super(relayService, elementService, historyService, store, modalService);
    this.relayService.loadDefaultPages(false);

    debounce(() => {
      this.setMaxFontSizeToFitContent('brand-title');
    }, 250)();

    const environment = environmentService.getEnvironment();
    this.hideLocationChanger = environment.hideLocationChanger;
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.initIdleTimer();
    this.activateGlobalEvents();
  }

  protected override updatePageStore($event: PortalUpdateEvent) {
    super.updatePageStore($event);
  }

  initIdleTimer() {
    // Start watching when user idle is starting.
    this.userIdleService
      .onTimerStart()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.idleLogoutWarning();
      });

    // Start watch when time is up.
    this.userIdleService
      .onTimeout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.idleLogout();
      });

    // Start watching
    this.userIdleService.startWatching();
  }

  async idleLogoutWarning() {
    if (!this.showingLogoutWarning) {
      this.showingLogoutWarning = true;
      const confirm = new Confirm();
      confirm.header = this.translation.getTranslation('IDLE_HEADER');
      confirm.message = this.translation.getTranslation('IDLE_MESSAGE');
      confirm.confirmOptions = [
        { text: this.translation.getTranslation('IDLE_BUTTON_LOGOUT'), value: false } as ConfirmOption,
        { text: this.translation.getTranslation('IDLE_BUTTON_CONTINUE'), value: true } as ConfirmOption,
      ];

      if (await this.modalService.confirm(confirm)) {
        this.userIdleService.resetTimer();
        this.showingLogoutWarning = false;
      } else {
        this.idleLogout();
      }
    }
  }

  idleLogout() {
    this.userIdleService.stopTimer();
    this.userIdleService.stopWatching();
    this.authService.logout().then();
  }

  toggleMenu(event: any) {
    event.stopPropagation();
    event.preventDefault();

    if (
      window.innerWidth < 1024 ||
      (!this.root?.nativeElement.classList.contains('mn--min') &&
        !this.root?.nativeElement.classList.contains('mn--max'))
    ) {
      this.root?.nativeElement.classList.toggle('mn--show');
    } else {
      this.root?.nativeElement.classList.toggle('mn--min');
      this.root?.nativeElement.classList.toggle('mn--max');
    }

    this.size = this.size == 'large' ? 'small' : 'large';
  }

  toggleBackdrop() {
    this.root?.nativeElement.classList.remove('mn--show');
    if (
      !this.root?.nativeElement.classList.contains('sb--pinned') &&
      !this.root?.nativeElement.classList.contains('sb-stuck')
    ) {
      this.root?.nativeElement.classList.remove('sb--show');
    }
  }

  prevent(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  setMaxFontSizeToFitContent(divId: string) {
    const div = document.getElementById(divId);
    if (!div) return;

    const maxWidth = div.clientWidth;
    const maxHeight = div.clientHeight;
    const text = div.textContent;

    let fontSize = 1;
    let minFontSize = 1;
    let maxFontSize = 100; // You can adjust the maximum font size as needed

    while (fontSize < maxFontSize) {
      div.style.fontSize = fontSize + 'px';
      if (div.scrollWidth > maxWidth || div.scrollHeight > maxHeight) {
        break;
      }
      minFontSize = fontSize;
      fontSize += 1;
    }

    div.style.fontSize = minFontSize + 'px';
    //div.style.lineHeight = minFontSize + 'px';
  }
}
