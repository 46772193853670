import { Injectable, Signal, effect } from '@angular/core';
import {
  cultures,
  Environment,
  EnvironmentService,
  HttpService,
  LocaleService,
  MainState,
  SetLanguage,
  SetSelectedLanguage,
  SetTranslations,
  Translation
} from '@cloud-and-service/portal-lib';
import { Store } from '@ngxs/store';


@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  environment: Environment;
  currentLanguage: Signal<string>;

  translations: Signal<Translation[]>;
  applicationLanguage: Signal<string | null>;

  languageLocaleMapping(culture: string): string {
    return cultures.filter((c) => c.language == culture).map(c => c.isoCode)[0] ?? '';
  }

  constructor(
    private http: HttpService,
    private store: Store,
    private localeService: LocaleService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
    this.translations = this.store.selectSignal(MainState.translations);
    this.applicationLanguage = this.store.selectSignal(MainState.language);
    this.currentLanguage = this.localeService.currentLanguageSignal;

    effect(() => this.getTranslations(null, this.currentLanguage()));
  }

  async addTranslation(translation: Translation): Promise<Translation> {
    let url = `${this.environment.apiUrl}/translation/add`;
    return this.http.post<Translation, Translation>(url, translation);
  }

  async updateTranslation(translation: Translation): Promise<Translation> {
    let url = `${this.environment.apiUrl}/translation/update`;
    return this.http.put<Translation, Translation>(url, translation);
  }

  async getTranslationsWithBlanks(tenantId: string | null, language: string | null): Promise<Translation[]> {
    let queryParams = { tenantId: tenantId, culture: language };
    let url = `${this.environment.apiUrl}/translation/list/withBlanks?${this.http.queryGenerator(queryParams)}`;
    return await this.http.get<Translation[]>(url);
  }

  async getTranslations(tenantId?: string | null, language?: string | null): Promise<Translation[]> {
    if (!tenantId) {
      tenantId = this.store.selectSnapshot(MainState.tenant)?.id;
    }
    if (!language) {
      language = this.currentLanguage();
    }

    let activeLanguageIsLanguage = this.applicationLanguage() === language;
    if (this.translations().length > 0 && activeLanguageIsLanguage) {
      return this.translations();
    }

    let queryParams = { tenantId: tenantId, culture: this.languageLocaleMapping(language) };
    let url = `${this.environment.apiUrl}/translation?${this.http.queryGenerator(queryParams)}`;
    return await this.http.get<Translation[]>(url).then((translations: Translation[]) => {
      this.store.dispatch(new SetTranslations(translations));
      this.store.dispatch(new SetLanguage(language));
      return translations;
    });
  }

  async getCurrentTranslations(): Promise<Translation[]> {
    return this.getTranslations();
  }
}
