import { Component, HostListener, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { DynamicState, Environment, EnvironmentService, LocaleService, MainState, Page, SetDefaultLanguage, SetLogo, SetRoutePrefix, SetTitle, Translation, UtilityService } from '@cloud-and-service/portal-lib';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RouteInfo } from './models/routeinfo';
import { InfoService } from './services/info.service';
import { MetaService } from './services/meta.service';
import { RouterParams } from './services/router-params.service';
import { TenantService } from './services/tenant.service';
import { TranslationService } from './services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly environment: Environment;

  @HostListener('document:keypress', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.code == 'KeyV') {
      var backendEnvironment = await this.infoService.backendEnvironment()
        .then(data => {
          return data.text;
        }).catch(
          () => {
            return '--';
          }
        );
      var backendVersion = await this.infoService.backendVersion()
        .then(data => {
          return data.text;
        }).catch(
          () => {
            return '--';
          }
        );

      var frontendEnvironment = this.environment.environment;
      var frontendVersion = this.environment.appVersion;

      var newLine = '\r';
      var msg = `Backend Env.: ${backendEnvironment}`;
      msg += newLine;
      msg += `Backend v.: ${backendVersion}`;
      msg += newLine;
      msg += `Frontend Env.: ${frontendEnvironment}`;
      msg += newLine;
      msg += `Frontend v.: ${frontendVersion}`;
      alert(msg);
    }
  }

  @Select(DynamicState.page) page$!: Observable<Page>;
  @Select(MainState.translations) translations$: Observable<Translation[]> | undefined;

  constructor(
    private localeService: LocaleService,
    private routerParams: RouterParams,
    private tenantService: TenantService,
    private utilityService: UtilityService,
    private metaService: MetaService,
    private infoService: InfoService,
    private store: Store,
    private translationService: TranslationService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getEnvironment();
  }

  async ngOnInit(): Promise<void> {
    this.getPortalSetings();
  }

  title = '365Portal';

  getPortalSetings() {
    this.routerParams.params.subscribe(
      async (params: Params) => {

        const routePrefix = params['forsyning'];
        let heading: string = '';
        let logo: string = '';
        const staticLogo = window.location.origin + '/assets/img/fo-logo.svg';

        this.store.dispatch(new SetRoutePrefix(routePrefix));

        await this.tenantService.getRouteInfo(routePrefix)
          .then((result: RouteInfo) => {

            if (!result) {
              return;
            }

            if (!this.utilityService.isEmpty(result.loginHeading)) {
              heading = result.loginHeading;
              this.store.dispatch(new SetTitle(heading));
            } else {
              heading = 'Forsyning Online';
              this.store.dispatch(new SetTitle(heading));
            }

            if (!this.utilityService.isEmpty(result.logoUrl)) {
              logo = `${this.environment.storageUrl}/logos/${routePrefix.toLowerCase()}-logo${result.logoUrl}`;
              this.store.dispatch(new SetLogo(logo));
            } else {
              logo = 'assets/img/fo-logo.svg';
              this.store.dispatch(new SetLogo(logo));
            }

            if (result.locale) {
              this.store.dispatch(new SetDefaultLanguage(result.locale));
            } else {
              this.store.dispatch(new SetDefaultLanguage('da'));
            }

            this.metaService.update(staticLogo, heading, 'Log ind');
          });

      });

  }

}
