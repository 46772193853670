import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'moment',
  standalone: false,
})
export class MomentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    if (args?.inFormat) {
      value = moment(value, args?.inFormat);
    }
    if (args?.fromNow) {
      return moment(value).fromNow();
    }
    if (args?.timeOnly) {
      return moment(value).format('HH:mm');
    }
    if (args?.time) {
      return moment(value).format('dddd DD MMMM, YYYY - HH:mm');
    }
    if (args?.time2) {
      return moment(value).format('DD-MM-YYYY - HH:mm');
    }
    if (args?.time3) {
      return moment(value).format('HH:mm');
    }
    if (args?.time4) {
      return moment(value).format('DD-MM-YYYY');
    }
    if (args?.stopWatch) {
      return moment.utc(moment().diff(value)).format('HH:mm:ss');
    }
    if (args?.outFormat) {
      return moment(value).format(args?.outFormat);
    }

    return moment(value).format('dddd DD MMMM, YYYY');
  }
}
