<div id="root" class="root mn--max tm--expanded-hd mn--sticky hd--sticky" (click)="toggleBackdrop()"
    data-bs-theme="light" data-scheme="navy">
    <section id="content" class="content">
        <div class="content__boxed overlapping" *ngIf="page$ | async as page">
            <portal-page [element]="page"></portal-page>
        </div>

        <!-- FOOTER -->
        <footer class="border-top mt-auto" *ngIf="footer$ | async as footer">
            <div class="content__boxed">
                <div class="content__wrap py-3 py-md-1">
                    <portal-footer [element]="footer"></portal-footer>
                </div>
            </div>
        </footer>
    </section>

    <header class="header">
        <div class="header__inner">
            <div class="header__brand" *ngIf="settings$ | async as settings">
                <div class="brand-wrap" (click)="setMaxFontSizeToFitContent('brand-title')">
                    <img [src]='logo$ | async' alt="logo" style="width: 32px;">
                    <div id="brand-title" class="brand-title">{{settings.utilityName}}</div>
                </div>
            </div>
            <div class="header__content">
                <div class="header__content-start">

                    <button (click)="toggleMenu($event)" type="button"
                        class="nav-toggler header__btn btn btn-icon btn-sm">
                        <i class="fa-light fa-bars"></i>
                    </button>

                </div>
            </div>
        </div>
    </header>

    <nav id="mainnav-container" class="mainnav" (click)="prevent($event)">
        <div class="mainnav__inner">
            <div class="mainnav__top-content scrollable-content pb-5">
                <div class="mainnav__profile mt-3">

                    <div class="text-center py-2 collapsed">
                        <img *ngIf="size === 'small'" [src]='logo$ | async ' alt="logo" style="width: 32px;">
                        <img *ngIf="size === 'large'" [src]='logo$ | async ' alt="logo" style="width: 64px;">
                    </div>

                    <div class="mininav-content  d-mn-max" *ngIf="settings$ | async as settings">
                        <div class="d-grid">
                            <button class="d-block btn shadow-none p-2" data-bs-toggle="collapse"
                                data-bs-target="#usernav" aria-expanded="false" aria-controls="usernav">
                                <span class="d-flex justify-content-center align-items-center">
                                    <h6 class="mb-0 me-2">{{ settings.userDescription }}</h6>
                                </span>
                                <small class="text-muted">{{ settings.userDescription2 }}</small>

                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="menu$ | async as menu">
                    <portal-menu [element]="menu" (toggleMenu)="toggleBackdrop()"></portal-menu>
                </div>

                <h6 class="mainnav__caption mt-3 px-3 fw-bold">{{'FUNCTIONS' | translation}}</h6>
                <ul class="mainnav__menu nav flex-column" (click)="toggleBackdrop()">
                    @if (!hideLocationChanger) {
                    <li class="nav-item" id="show-locations" (click)="locationService.presentLocations(false)">
                        <a href="#" class="nav-link mininav-toggle collapsed"><i
                                class="fa-light fa-pump fs-5 me-2 "></i>
                            <span class="nav-label mininav-content">{{'LOCATIONS' | translation}}</span>
                        </a>
                    </li>
                    <li class="nav-item" id="mit-id-add" (click)="locationService.presentMitIDModal()">
                        <a href="#" class="nav-link mininav-toggle collapsed">
                            <i class="fa-light fa-id-card fs-5 me-2"></i>
                            <span class="nav-label mininav-content">{{ 'ADD_MITID_LOCATION' | translation }}</span>
                        </a>
                    </li>
                    }
                    <li class="nav-item" (click)="authService.logout();">
                        <a href="#" class="nav-link mininav-toggle collapsed"><i
                                class="fa-light fa-arrow-right-from-bracket fs-5 me-2 "></i>
                            <span class="nav-label mininav-content">{{'LOGOUT' | translation}}</span>
                        </a>
                    </li>
                    <!--
                    <li class="nav-item" (click)="toggleMenu($event)">
                        <a href="#" class="nav-link mininav-toggle collapsed"><i
                                class="fa-light fa-bars fs-5 me-2 "></i>
                            <span class="nav-label mininav-content">{{'MENU' | translation}}</span>
                        </a>
                    </li>
                    -->
                </ul>
            </div>
        </div>
    </nav>
</div>