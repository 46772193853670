<section style="background-color: white;" class="section-container">
    <div class="container-fluid">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-md-9 col-lg-6 col-xl-5 text-center">
                <img [src]="logo()" class="img-fluid mx-5 mt-5 mt-xl-0" alt="Forsyning Online Logo">
                <p class="display-6 mt-3 text-center">{{title()}}</p>
            </div>
            <div class="col-10 col-xs-6 col-md-8 col-lg-6 col-xl-4 offset-xl-1">

                <div class="d-grid mt-3" *ngIf="loginPageSettings.allowNemId">
                    <button class="btn btn-primary" (click)="getIdpService('mitid')">{{'MITID' | translation}}</button>
                    <div class="alert alert-light mt-3" role="alert">
                        <span id="mit-id-info-box">{{ 'MITID_LOGIN_INFO' | translation }}</span>
                    </div>
                </div>
                <!-- <div class="d-grid mt-3">
                    <button class="btn btn-outline-primary" (click)="getIdpService('nemid')">{{'NEMID' |
                        translation}}</button>
                </div> -->

                <div class="divider d-flex align-items-center my-4" *ngIf="loginPageSettings.allowNemId">
                    <p class="text-center fw-bold mx-3 mb-0">{{'OR' | translation | lowercase}}</p>
                </div>

                <!-- Email input -->
                <div class="form-outline mb-2">
                    <label class="form-label" for="form3Example3">{{'USERNAME' | translation}}</label>
                    <input [(ngModel)]="username" type="email" id="form3Example3" class="form-control form-control-lg"
                        placeholder="{{'USERNAME' | translation}}" />
                </div>

                <!-- Password input -->
                <div class="form-outline mb-2">
                    <label class="form-label" for="form3Example4">{{'PASSWORD' | translation}}</label>
                    <input [(ngModel)]="password" type="password" id="form3Example4"
                        class="form-control form-control-lg" placeholder="{{'PASSWORD' | translation}}"
                        (keyup.enter)="login()" />
                </div>

                <div class="d-grid my-3">
                    <button type="button" class="btn btn-primary" (click)="login()">
                        {{'LOGIN' | translation}}
                    </button>
                    <p class="small fw-bold mt-2 pt-1 mb-0">
                        {{'LOGIN_HELP' | translation}}
                        <a href="#!" class="link-danger">{{'HELP' | translation}}</a>
                    </p>
                </div>

                <div class="divider d-flex align-items-center my-4" *ngIf="loginPageSettings.allowQuickConsumption">
                    <p class="text-center fw-bold mx-3 mb-0">{{'OR' | translation | lowercase}}</p>
                </div>

                <div class="d-grid my-4" *ngIf="loginPageSettings.allowQuickConsumption">
                    <button class="btn btn-outline-primary"
                        (click)="consumptionService.quickConsumptionReading()">{{'QUICK_CONSUMPTION_READING' |
                        translation}} <i class="fa-solid fa-chevron-right ms-1"></i></button>
                </div>
                @if(cultures.length > 1) {
                <div class="col-6">
                  <select class="form-control" #languageSelect (change)="changeLocale($event)">
                    @for (languageOption of cultures; track $index) {
                    <option [value]="languageOption" [selected]="selectedLanguage() === languageOption">{{ languageOption | translation }}</option>
                    }
                  </select>
                </div>
                }
            </div>
        </div>
    </div>
</section>
