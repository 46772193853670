import { Component, computed, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { Culture, cultures, TranslationService as LibTranslationService, Tenant, Translation } from '@cloud-and-service/portal-lib';
import { TenantService } from 'src/app/services/tenant.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-admin-translation-list',
  templateUrl: './admin-translation-list.component.html',
  styleUrls: ['./admin-translation-list.component.scss'],
})
export class AdminTranslationListComponent implements OnInit {
  globalTenant: Tenant = { name: 'Global', id: null } as Tenant;
  currentTenant: Tenant = this.globalTenant;
  tenants: Tenant[] = [];
  translations: Translation[] = [];
  culture: WritableSignal<Culture>;
  cultures = cultures;
  language: Signal<string>;
  translation: Translation;

  constructor(private translationService: TranslationService, private libTranslationService: LibTranslationService, private tenantService: TenantService) {
    this.culture = signal(cultures.filter((c) => c.language == 'da')[0]);
    this.language = computed(() => this.libTranslationService.getTranslation(this.culture().language));
    this.translation = { culture: this.culture().isoCode, tenantId: this.currentTenant.id } as Translation;
  }

  async ngOnInit(): Promise<void> {
    this.getTenants();
    this.getTranslations();
  }

  getTenants() {
    this.tenantService.getTenants().then((tenants: Tenant[]) => {
      this.tenants = tenants;
    });
  }

  changeCulture(culture: Culture) {
    this.culture.set(culture);
    this.getTranslations();
  }

  changeTenant(tenant: Tenant) {
    this.currentTenant = tenant;
    this.getTranslations();
  }

  edit(translation: Translation) {
    this.translation = translation;
  }

  clone(translation: Translation) {
    const clone: Translation = {} as Translation;
    clone.key = translation.key;
    clone.value = translation.value;
    clone.culture = translation.culture;
    clone.tenantId = this.currentTenant.id;
    this.translation = clone;
  }

  getTranslations() {
    this.translationService
      .getTranslationsWithBlanks(this.currentTenant.id, this.culture().isoCode)
      .then((translations: Translation[]) => {
        this.translations = translations;
        this.translation = { culture: this.culture().isoCode, tenantId: this.currentTenant.id } as Translation;
      });
  }

  addTranslation() {
    if (!this.translation.key || !this.translation.value || !this.translation.culture) {
      return;
    }
    let matchingKeyTranslation = this.translations.find((t) => t.key === this.translation.key);
    if (matchingKeyTranslation) {
      this.translation.id = matchingKeyTranslation.id;
      this.updateTranslation();
      return;
    }
    this.translation.culture = this.culture().isoCode;
    this.translation.key = this.translation.key.toUpperCase();
    this.translation.tenantId = this.currentTenant.id;
    this.translationService.addTranslation(this.translation).then((translation: Translation) => {
      this.getTranslations();
    });
  }

  updateTranslation() {
    if (!this.translation.id || !this.translation.key || !this.translation.value || !this.translation.culture) {
      return;
    }
    this.translation.culture = this.culture().isoCode;
    this.translation.key = this.translation.key.toUpperCase();
    this.translation.tenantId = this.currentTenant.id;
    this.translationService.updateTranslation(this.translation).then((translation: Translation) => {
      this.getTranslations();
    });
  }

  async getCurrentTranslations() {
    await this.translationService.getCurrentTranslations();
  }

  fixKey() {
    if (this.translation.key?.length) {
      this.translation.key = this.translation.key.replace(' ', '_');
      this.translation.key = this.translation.key.replace('-', '_');
      this.translation.key = this.translation.key.replace('.', '_');
      this.translation.key = this.translation.key.replace(/[^a-zA-Z0-9_]/g, '');
      this.translation.key = this.translation.key.toUpperCase();
    }
  }
}
