import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '@cloud-and-service/portal-lib';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: false,
})
export class AdminComponent {

  @ViewChild('root') root: ElementRef | null = null;

  user = { name: 'Marius Pallisgaard Olsen', email: 'mpo@elbek-vejrup.dk' };
  supplier = { name: 'Forsyningen' };

  size: string = 'large';

  constructor(
    public authService: AuthService
  ) { }

  async logout() {
    await this.authService.logout();
  }

  toggleMenu(event: any) {
    event.stopPropagation();
    event.preventDefault();

    if (window.innerWidth < 1024 || (!this.root?.nativeElement.classList.contains("mn--min") && !this.root?.nativeElement.classList.contains("mn--max"))) {
      this.root?.nativeElement.classList.toggle("mn--show");
    } else {
      this.root?.nativeElement.classList.toggle("mn--min");
      this.root?.nativeElement.classList.toggle("mn--max");
    }

    this.size = this.size == 'large' ? 'small' : 'large';
  }

  toggleBackdrop() {
    this.root?.nativeElement.classList.remove("mn--show");
    if (!this.root?.nativeElement.classList.contains("sb--pinned") && !this.root?.nativeElement.classList.contains("sb-stuck")) {
      this.root?.nativeElement.classList.remove("sb--show");
    }

    //this.size = this.size == 'large' ? 'small' : 'large'
  }

  prevent(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

}
