import { Component } from '@angular/core';
import { Confirm, ConfirmOption, Location, ModalService } from '@cloud-and-service/portal-lib';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
  standalone: false,
})
export class LocationListComponent {
  fromLogin: boolean = true;
  location: Location = {} as Location;

  public onClose: Subject<any>;

  public locations: Location[] = [];

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  setLocation(location: Location) {
    this.onClose.next(location);
    this.bsModalRef?.hide();
  }

  close() {
    this.bsModalRef?.hide();
  }
}
