import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Guid } from 'guid-typescript';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { Culture, cultures, Environment, EnvironmentService } from '@cloud-and-service/portal-lib';
import { Portal } from '@cloud-and-service/portal-lib';
import { Tenant } from '@cloud-and-service/portal-lib';
import { UtilityService } from '@cloud-and-service/portal-lib';
import { UpdatePasswordRequest } from 'src/app/models/update-password-request';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-admin-tenant-detail',
  templateUrl: './admin-tenant-detail.component.html',
  styleUrls: ['./admin-tenant-detail.component.scss'],
  standalone: false,
})
export class AdminTenantDetailComponent implements OnInit {
  @ViewChild('tenantprefix') tenantprefix!: ElementRef;
  @ViewChild('portalroute') portalroute!: ElementRef;

  tenant: Tenant = {} as Tenant;
  portalThemes: string[] = [];
  fileToUpload: File = {} as File;
  allowSave: boolean = false;
  environment: Environment;
  cultures: Culture[];

  public onClose: Subject<any>;
  pageId: string = 'nav';

  constructor(
    private tenantService: TenantService,
    private utilityService: UtilityService,
    private toastrService: ToastrService,
    public bsModalRef: BsModalRef,
    environmentService: EnvironmentService
  ) {
    this.onClose = new Subject();
    this.environment = environmentService.getEnvironment();
    this.cultures = cultures;
  }

  ngOnInit() {
    if (this.utilityService.isEmpty(this.tenant.portal)) {
      this.tenant.portal = {} as Portal;
    }
    this.validateFields();
    this.tenantService.getThemes().then((result: string[]) => {
      this.portalThemes = result;
    });
  }

  showPage(pagename: string): void {
    this.pageId = pagename;
  }

  validateFields() {
    this.allowSave =
      !this.utilityService.isEmpty(this.tenant.prefix) &&
      !this.utilityService.isEmpty(this.tenant.name) &&
      !this.utilityService.isEmpty(this.tenant.portal.route) &&
      this.validateLicenceId(this.tenant.licenceId);
  }

  validateLicenceId(licenceId: string | null): boolean {
    return (!this.utilityService.isEmpty(licenceId) && Guid.isGuid(licenceId)) || !this.environment.licenceRequired;
  }

  async addTenant() {
    if (await this.tenantService.prefixExists(this.tenant.prefix)) {
      this.toastrService.error(`Prefix: [${this.tenant.prefix}] bruges allerede.`);
      this.pageId = 'nav';
      this.tenantprefix.nativeElement.focus();
      return;
    }

    if (
      !this.utilityService.isEmpty(this.tenant.portal.route) &&
      (await this.tenantService.routeExists(this.tenant.portal.route))
    ) {
      this.toastrService.error(`Url-rute: [${this.tenant.portal.route}] bruges allerede.`);
      this.pageId = 'portal';
      this.portalroute.nativeElement.focus();
      return;
    }

    this.tenantService.addTenant(this.tenant).then(() => {
      this.bsModalRef?.hide();
      this.toastrService.success(`${this.tenant.name} er oprettet.`);
    });
  }

  updateTenant() {
    if (!this.utilityService.isEmpty(this.fileToUpload.size) && this.fileToUpload.size != 0) {
      this.tenantService.uploadLogo(this.tenant.portal.route, this.fileToUpload);
      this.tenant.portal.logoType = this.fileToUpload.type;
    }
    this.tenantService.update(this.tenant).then(() => {
      this.bsModalRef?.hide();
      this.toastrService.success(`Ændringer til ${this.tenant.name} er gemt.`);
    });
  }

  updateTenantServicePassword() {
    if (!this.tenant.id || this.tenant.id == Guid.EMPTY) {
      this.toastrService.error(`Tenant skal være gemt før der kan tilføjes et servicePassword.`);
      return;
    }

    if (!this.tenant.servicePassword || !this.tenant.servicePassword.length) {
      this.toastrService.error(`Der skal være et password`);
      return;
    }

    let request: UpdatePasswordRequest = {} as UpdatePasswordRequest;
    request.id = this.tenant.id;
    request.password = this.tenant.servicePassword;
    this.tenantService.updateTenantServicePassword(request).then(() => {
      this.toastrService.success(`Ændringer til ${this.tenant.name} er gemt.`);
    });
  }

  updateTenantRestBasicAuthPassword() {
    if (!this.tenant.id || this.tenant.id == Guid.EMPTY) {
      this.toastrService.error(`Tenant skal være gemt før der kan tilføjes et servicePassword.`);
      return;
    }

    if (!this.tenant.restBasicAuthPassword || !this.tenant.restBasicAuthPassword.length) {
      this.toastrService.error(`Der skal være et password`);
      return;
    }

    let request: UpdatePasswordRequest = {} as UpdatePasswordRequest;
    request.id = this.tenant.id;
    request.password = this.tenant.restBasicAuthPassword;
    this.tenantService.updateTenantRestBasicAuthPassword(request).then(() => {
      this.toastrService.success(`Ændringer til ${this.tenant.name} er gemt.`);
    });
  }

  updateTenantRestOAuthSecret() {
    if (!this.tenant.id || this.tenant.id == Guid.EMPTY) {
      this.toastrService.error(`Tenant skal være gemt før der kan tilføjes et servicePassword.`);
      return;
    }

    if (!this.tenant.restOAuthClientSecret || !this.tenant.restOAuthClientSecret.length) {
      this.toastrService.error(`Der skal være et password`);
      return;
    }

    let request: UpdatePasswordRequest = {} as UpdatePasswordRequest;
    request.id = this.tenant.id;
    request.password = this.tenant.restOAuthClientSecret;
    this.tenantService.updateTenantRestOAuthSecret(request).then(() => {
      this.toastrService.success(`Ændringer til ${this.tenant.name} er gemt.`);
    });
  }

  validroute() {
    if (this.tenant.portal.route?.length) {
      this.tenant.portal.route = this.tenant.portal.route
        .replace('_', '-')
        .replace(',', '-')
        .replace('.', '-')
        .replace(/[^a-zA-Z0-9-]/g, '')
        .toLowerCase();
    }
    this.validateFields();
  }

  compareThemes(p1: string, p2: string): boolean {
    if (p1 && p2) {
      return p1 === p2;
    }
    return false;
  }

  onFileUploadChange(event: any) {
    if (event.target.files.length) {
      const file: File | null = event.target.files.item(0);
      if (file !== null) {
        this.fileToUpload = file;
        this.tenant.portal.logo = file.name;
      }
    }
  }
}
